.product {
    position: relative;
    width: 170px;
    flex: none;
}
.redesignCommonMode.product {
    width: 160px;
}
.commonV3Mode.product,
.imageWithTitleSnippet.product {
    width: 162px;
}
.isMobile.product {
    width: 155px;
}
.commonV3Mode.isMobile.product {
    width: 124px;
}

.link {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 4px;
    background-color: #fff;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}
.redesignCommonMode .link {
    border-radius: 6px;
}
.commonV3Mode .link {
    border-radius: 10px;
}
.onlyImage .link,
.onlyImage.isMobile .link {
    background: transparent;
}

.imageContainer {
    display: flex;
    padding: 6px;
}
.redesignCommonMode .imageContainer {
    padding: 0;
    background: transparent;
}
.commonV3Mode.isMobile .imageContainer {
    padding: 4px;
    background: transparent;
}
.onlyImage .imageContainer,
.onlyImage.isMobile .imageContainer {
    padding: 0;
    background: rgba(211, 211, 211, 0.44) 50%;
}
.imageWithTitleSnippet .imageContainer {
    padding: 12px 12px 0;
}
.imageWithTitleSnippet.isMobile .imageContainer {
    padding: 10px 10px 0;
}
.commonV3Mode.onlyImage .imageContainer {
    position: relative;
    height: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 4px 6px 10px;
    gap: var(--gap);

    --gap: 7px;
}
.commonV3Mode .content {
    --gap: 4px;
}
.redesignCommonMode .content,
.commonV3Mode.isMobile .content {
    padding: 4px 10px 8px;
    --gap: 4px;
}
.redesignCommonMode.isMobile .content {
    padding-bottom: 6px;
}
.imageWithTitleSnippet .content {
    padding: 0 12px 12px;
    --gap: 0;
}
.imageWithTitleSnippet.isMobile .content {
    padding: 0 10px 10px;
}

.image {
    width: 158px;
    height: 158px;
    border-radius: 6px;
    object-fit: cover;
    pointer-events: none;
}
.redesignCommonMode .image {
    width: 160px;
    height: 160px;
    border-radius: 6px 6px 0 0;
}
.onlyImage .image {
    width: 154px;
    height: 216px;
    border-radius: 6px;
    background: transparent;
    margin: auto;
    pointer-events: none;
}

.commonV3Mode .image {
    width: 100%;
    height: 150px;
    border-radius: 6px;
}
.commonV3Mode.onlyImage .image {
    position: absolute;
    width: 100%;
    height: 100%;
}
.commonV3Mode.onlyImageSnippets .image {
    position: unset;
}
.imageWithTitleSnippet .image {
    height: 150px;
    width: 100%;
    border-radius: 0;
}
.isMobile .image {
    width: 143px;
    height: 143px;
}
.onlyImage.isMobile .image {
    width: 155px;
    height: 100%;
}
.commonV3Mode.isMobile .image {
    width: 116px;
    height: 116px;
}
.commonV3Mode.onlyImage.isMobile .image {
    width: 124px;
    height: 100%;
}
.imageWithTitleSnippet.isMobile .image {
    height: 118px;
    width: 100%;
}
.badgeContainer {
    height: 0;
    position: relative;
}
.isMobile .badgeContainer {
    height: 4px;
}
.badge {
    position: absolute;
    top: -16px;
    left: 0;
    width: max-content;
    font-size: 10px;
    line-height: 12px;
    font-weight: 600;
    padding: 2px 1px 2px 4px;
    background: #fe2722;
    color: #fff;
}
.commonV3Mode .badge {
    font-size: 12px;
    padding: 4px 1px 4px 5px;
    top: -20px;
}
.commonV3Mode.isMobile .badge {
    font-size: 10px;
    letter-spacing: 0.1px;
    padding: 2px 0 2px 4px;

    top: -11px;
}
.badge::after {
    content: "";
    position: absolute;
    top: 0;
    /* Выходим за пределы нашего блока на пиксель меньше, чтобы при зуме страницы */
    /* не было глитчей с полоской, из которой просачивается контент под бэджиком */
    right: -4px;
    width: 5px;
    height: 100%;
    background: inherit;
    /* иногда clip-path зачем-то срезает часть контента сверху, и чтобы исправить это, */
    /* натянул обрезку на 5% выше, чем видимый контент */
    clip-path: polygon(0 0, 100% -5%, 40% 100%, 0 100%);
}
.badgeCoupon {
    position: absolute;
    top: -20px;
    left: 0;
    width: max-content;
    display: inline-flex;
    padding-left: 6px;
}
.isMobile .badgeCoupon {
    top: -11px;
    padding-left: 4px;
}
.redesignCommonMode.isMobile .badgeCoupon {
    top: -16px;
}
.redesignCommonMode .badgeCoupon {
    padding-left: 0;
}
.onlyImage .badgeCoupon,
.onlyImage.isMobile .badgeCoupon {
    padding-left: 0;
}
.badgeCouponText {
    display: flex;
    padding: 1px 2px 2px 2px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 15px;
    margin: 0 -1px;

    font-weight: 600;
    color: #2754e5;
    background-color: #e0e8ff;
}
.isMobile .badgeCouponText {
    font-size: 10px;
    line-height: 12px;
}

.priceContainer {
    display: flex;
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    border-radius: 6px;
    background: #f0f4f7;
    padding: 3px 6px 6px 6px;
}
.redesignCommonMode .priceContainer,
.commonV3Mode .priceContainer {
    background: transparent;
    padding: 0;
}

.price {
    font-size: 17px;
    line-height: 20px;
    font-weight: 600;
    white-space: nowrap;
}
.redesignCommonMode .price,
.commonV3Mode .price {
    font-size: 17px;
    line-height: 20px;
}
.isMobile .price {
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
}
.uzsPrice {
    font-size: 15px;
}

.discount {
    color: #000;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 0.065px;
}
.discountSpace {
    height: 16px;
}
.isMobile .discount {
    font-size: 11px;
    line-height: 12px;
}
.isMobile .discountSpace {
    height: 12px;
}
.uzsDiscount {
    font-size: 10px;
    word-break: break-all;
}

.cashbackText {
    height: 20px;
    font-size: 13px;
    line-height: 16px;
    padding-top: 4px;
    margin-left: 2px;
}
.redesignCommonMode .cashbackText,
.commonV3Mode .cashbackText {
    height: 16px;
    padding-top: 0;
    margin-left: 0;
}

.title {
    width: 100%;
    flex: none;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.065px;
}
.redesignCommonMode .title {
    height: 16px;
    max-height: 24px;
}
.redesignCommonMode.isMobile .title {
    height: 12px;
}
.redesignCommonMode .title,
.commonV3Mode .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-all;
}
.commonV3Mode .tallerTitle {
    -webkit-line-clamp: 2;
    word-break: unset;
}
.titleForOnlyImage {
    font-size: 15px;
    line-height: 18px;
    margin-top: auto;
}
.isMobile .title {
    font-size: 11px;
    line-height: 12px;
}

.deliveryText {
    font-size: 13px;
    line-height: 16px;
}
.isMobile .deliveryText {
    font-size: 11px;
    line-height: 12px;
}

.stocksText {
    font-size: 12px;
    line-height: 16px;
}
.isMobile .stocksText {
    font-size: 11px;
    line-height: 12px;
}

.ordersCountText {
    font-size: 13px;
    line-height: 16px;
}
.isMobile .ordersCountText {
    font-size: 11px;
    line-height: 12px;
}

.bookmarkIcon {
    position: absolute;
    top: -3px;
    left: 12px;
}
.isMobile .bookmarkIcon {
    width: 27px;
    height: 29px;

    left: 6px;
}
